import {gsap} from 'gsap'



// ******************
// Selectors
// ******************
const navBtn = document.querySelector('.nav--btn')
const formSection = document.querySelector('.form')
const contactForm = document.getElementById('contactForm')
const contactBtn = document.getElementById('contactBtn')
const contactBtn2 = document.getElementById('contactBtn2')
const nextBtn = document.querySelector('.form--moveBtn_next')
const prevBtn = document.querySelector('.form--moveBtn_prev')
const submitBtn = document.querySelector('.form--submit')
const inputGroups = contactForm.querySelectorAll('.form--inputGroup')
// const messageInput = document.getElementById('message')
const formCloseBtn = formSection.querySelector('.closeBtn')


// ******************
// Starter Variables
// ******************
const inputCount = inputGroups.length
let topZ = 701
let currentIndex = 0
let currentGroup
let nextGroup


function createOverlay() {
  const overlay = document.createElement('div')
  overlay.classList.add('overlay')
  document.querySelector('body').appendChild(overlay)
}


function showContactForm() {
  document.querySelector('body').style.overflow = 'hidden'
  createOverlay()
  document.querySelector('.overlay').addEventListener('click', closeContactForm)
  gsap.timeline()
  .from('.overlay', {opacity: 0})
  .to(formSection, {xPercent: -100}, '<')

}


function closeContactForm() {
  const overlay = document.querySelector('.overlay')
  gsap.timeline({onComplete: () => {
    overlay.remove()
    document.querySelector('body').style.overflow = 'auto'
  }})
  .to(overlay, {opacity: 0, duration: .7})
  .to(formSection, {xPercent: 100, ease: 'power2.in', duration: .7}, '<')
}


const changeZIndex = () => {
  currentGroup.style.zIndex = topZ -1
  nextGroup.style.zIndex = topZ
}


function showError() {
  const errorContainer = document.createElement('div')
  errorContainer.classList.add('form--error')
  const errorText = document.createElement('p')
  errorText.innerText = 'Se te ha olvidado algo...'
  formSection.appendChild(errorContainer)
  errorContainer.appendChild(errorText)
  gsap.timeline({onComplete: removeErrorMessage})
    .from(errorContainer, {yPercent: 100, opacity: 0})
    .to({}, {duration: 2})
}


function removeErrorMessage() {
  const errorContainer = document.querySelector('.form--error')
  gsap.to(errorContainer, {yPercent: 100, opacity: 0, ease: 'power2.in', onComplete: () => {errorContainer.remove()}})
}


function emailIsValid(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

function showNext() {
  if(currentIndex == (inputCount - 1)) return
  let error = false

  inputGroups.forEach((group, idx) => {
    if(idx == currentIndex) {
      currentGroup = group
      const input = group.querySelector('.form--inputGroup__input')
      input.classList.remove('error')
      if(input.value.trim().length < 1 || input.value == null) {
        input.classList.add('error')
        error = true
      }

      if(input.id == 'email') {
        if(emailIsValid(input.value) == false) {
          input.classList.add('error')
          error = true
        }
      }
    }

    if(idx == currentIndex + 1 ) nextGroup = group
  })

  if(error) {
    showError()
    return
  }

  prevBtn.style.display = 'block'

  gsap.timeline({
    defaults: {duration: 0.5},
    onComplete: changeZIndex
  })
  .to(currentGroup, {opacity: 0})
  .to(nextGroup, {opacity: 1})
  .to(prevBtn, {opacity: 1}, '<')

  currentIndex++

  if(currentIndex == (inputCount - 1)) {
    submitBtn.style.display = 'block'
    gsap.timeline({onComplete: function() {nextBtn.style.display = 'none'}})
    .from(submitBtn, {opacity: 0})
    .to(nextBtn, {opacity: 0}, '<')
  }
}


function showPrev() {
  if(currentIndex == (inputCount - 1)) {
    nextBtn.style.display = 'block'
    gsap.timeline({onComplete: function() {submitBtn.style.display = 'none'}})
    .to(nextBtn, {opacity: 1})
    .to(submitBtn, {opacity: 0}, '<')
  }

  inputGroups.forEach((group, idx) => {
    if(idx == currentIndex) currentGroup = group
    if(idx == currentIndex - 1 ) nextGroup = group
  })

  gsap.timeline({
    defaults: {duration: 0.5},
    onComplete: changeZIndex
  })
  .to(currentGroup, {opacity: 0})
  .to(nextGroup, {opacity: 1})
  currentIndex--

  if(currentIndex == 0) {
    gsap.to(prevBtn, {opacity: 0, onComplete: function() {prevBtn.style.display = 'none'}})
  }
}



async function submitContactForm() {
  const mailEndpoint = 'https://formsubmit.co/ajax/ventas@servamed.com.mx'
  const fullName = document.getElementById('fullname').value
  const email = document.getElementById('email').value
  const phone = document.getElementById('phone').value
  // const message = document.getElementById('message').value

  // if(message == null || message.length < 1) {
  //   messageInput.classList.add('error')
  //   showError()
  //   return
  // }

  const inputs = document.querySelectorAll('.form--inputGroup__input')
  inputs.forEach(input => {
    input.value = null
  })
  currentIndex = 0

  try {
    const res = await fetch(mailEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({fullName, email, phone}),
    })
    const data = await res.json()

  } catch (error) {
    console.error(error)
  }

  // const nameGroup = document.getElementById('ig_name')
  // const messageGroup = document.getElementById('ig_message')
  // nameGroup.style.zIndex++
  // messageGroup.style.zIndex--
  // currentGroup = null
  // nextGroup = null
  // gsap.to(messageGroup, {opacity: 0})
  // gsap.to(nameGroup, {opacity: 1})

  showSuccessMessage()
}


function showSuccessMessage() {
  const overlay = document.querySelector('.overlay')
  const messageContainer = document.createElement('div')
  messageContainer.classList.add('form--success')
  const messageText = document.createElement('p')
  messageText.innerText = `Gracias! Nos pondremos en contacto pronto.`
  overlay.appendChild(messageContainer)
  messageContainer.appendChild(messageText)
  gsap.timeline({onComplete: removeSuccessMessage})
  .to(formSection, {xPercent: 100, ease: 'power2.in', duration: .7}, '<')
  .from(messageContainer, {y: 20, opacity: 0})
  .to({}, {duration: 2})
}

function removeSuccessMessage() {
  gsap.timeline({onComplete: () => {
    const nameGroup = document.getElementById('ig_name')
    const phoneGroup = document.getElementById('ig_phone')
    // const messageGroup = document.getElementById('ig_message')
    prevBtn.style.opacity = 0
    prevBtn.style.display = 'none'
    nextBtn.style.opacity = 1
    nextBtn.style.display = 'block'
    submitBtn.style.display = 'none'
    nameGroup.style.zIndex++
    nameGroup.style.opacity = 1
    phoneGroup.style.zIndex--
    phoneGroup.style.opacity = 0
    // messageGroup.style.zIndex--
    // messageGroup.style.opacity = 0
    currentGroup = null
    nextGroup = null
    document.querySelector('.form--success').remove()
    document.querySelector('.overlay').remove()
    document.querySelector('body').style.overflow = 'auto'
  }})
  .to('.form--success', {y: -20, opacity: 0})
  .to('.overlay', {opacity: 0}, '<')
}



// ******************
// Event Listeners
// ******************
contactBtn.addEventListener('click', showContactForm)
contactBtn2.addEventListener('click', showContactForm)
formCloseBtn.addEventListener('click', closeContactForm)
navBtn.addEventListener('click', showContactForm)

prevBtn.addEventListener('click', (e) => {
  e.preventDefault()
  showPrev()
})

nextBtn.addEventListener('click', (e) => {
  e.preventDefault()
  showNext()
})

submitBtn.addEventListener('click', (e) => {
  e.preventDefault()
  submitContactForm()  
})


const two_fac = document.getElementById('two_fac')
const two_corp = document.getElementById('two_corp')
const facparts = two_fac.querySelectorAll('.twopart')
const corpparts = two_corp.querySelectorAll('.twopart')
const facNextBtn = document.getElementById('facNextBtn')
const facPrevBtn = document.getElementById('facPrevBtn')

let twoCounter = 1

function changeResImg(maxCount, section) {  
  let reset = false
  if(twoCounter == maxCount) {
    twoCounter = 1
    reset = true
  } else {
    twoCounter++
    reset = false
  }
  
  section.forEach(part => {
    const images = part.querySelectorAll('img')
    images.forEach(image => {
      const prepart = image.src.split('_')[0]
      const postpart = image.src.split('_')[2]
      image.src = prepart + '_' + twoCounter + '_' + postpart
    })
  })
}

function prevResImg(maxCount, section) {
  let reset = false
  if(twoCounter == 10) {
    reset = true
  } else {
    reset = false
  }

  if(twoCounter == 1) {
    twoCounter = maxCount
  } else {
    twoCounter--
  }

  section.forEach(part => {
    const images = part.querySelectorAll('img')
    images.forEach(image => {
      const prepart = image.src.split('_')[0]
      const postpart = image.src.split('_')[2]
      image.src = prepart + '_' + twoCounter + '_' + postpart
    })
  })

}

facNextBtn.addEventListener('click', () => {
  changeResImg(10, facparts)
})

facPrevBtn.addEventListener('click', () => {
  prevResImg(10, facparts)
})
corpNextBtn.addEventListener('click', () => {
  changeResImg(12, corpparts)
})

corpPrevBtn.addEventListener('click', () => {
  prevResImg(12, corpparts)
})
particlesJS("machine",
  {
    "particles": {
      "number": {
        "value": 60,
        "density": {
          "enable": true,
          "value_area": 1000
        }
      },
      "color": {
        "value": ["#817567", "#b59e80", "#e3d8ca", "#8c7455", "#88837d", "#faf7f6", "#f0e4d8"]
      },

      "shape": {
        "type": "edge", //circle, edge, triangle, polygon, star, image 複数指定["circle", "triangle", "image"]

        "stroke": {
          "width": 0,
          "color": "#000000"
        },
        
        "polygon": {
          "nb_sides": 5
        },

        // "image": {
        //   "src": "img/github.svg",
        //   "width": 100,
        //   "height": 100
        // }
      },

      "opacity": {
        "value": 0.5,
        "random": true, 

        "anim": {
          "enable": true, 
          "speed": 1,
          "opacity_min": 0.1,
          "sync": false 
        }
      },

      "size": {
        "value": 50,
        "random": true, 
        "anim": {
          "enable": false, 
          "speed": 10,
          "size_min": 1,
          "sync": false 
        }
      },

      "line_linked": {
        "enable": false, 
        "distance": 150,
        "color": "#ffffff",
        "opacity": 0.4,
        "width": 1
      },
      
      "move": {
        "enable": true, 
        "speed": 4,
        "direction": "top", //none, top, top-right, right, bottom-right, bottom, bottom-left, left, top-left
        "random": false, 
        "straight": false, 
        "out_mode": "out", //ボックス内で動かす bounce ボックス外に逃がす out
        "attract": {
          "enable": true,
          "rotateX": 1000,
          "rotateY": 1000
        }
      }
    },
  
    "interactivity": {
      "detect_on": "canvas",

      "events": {
        "onhover": {
          "enable": false, 
          "mode": "bubble" //grad:付近のシェイプと線を繋ぐ, bubble:拡大, repulse:拒絶
        },

        "onclick": {
          "enable": true, 
          "mode": "push" //push:追加, remove:削除, bubble:拡大, repulse:拒絶
        },
        "resize": true
      },
      
      "modes": {
        "grab": {
          "distance": 300,
          "line_linked": {
            "opacity": 1
          }
        },
        "bubble": {
          "distance": 100,
          "size": 7.5,
          "duration": 2,
          "opacity": 8,
          "speed": 3
        },
        "repulse": {
          "distance": 1
        },
        "push": {
          "particles_nb": 4
        },
        "remove": {
          "particles_nb": 2
        }
      }
    },
    //Retina Display対応
    "retina_detect": true, 
  }
);
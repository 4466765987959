import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
gsap.registerPlugin(ScrollTrigger, ScrollSmoother)

let smoother = ScrollSmoother.create({
  wrapper: '#wrapper',
  content: '#content',
  smooth: 1.5,
})

// ****************
// INTRO Animations
// ****************

const introSection = document.querySelector('.intro')
const introPresenting = document.querySelector('.intro--presenting')
const introTitle = document.querySelector('.intro--title')
const introSubtitle = document.querySelector('.intro--subtitle')
const introReveal = document.querySelector('.intro--reveal')
const introImg = introReveal.querySelector('img')

const introAnim = gsap.timeline({
  scrollTrigger: {
    trigger: introReveal,
    toggleActions: 'restart none none reset'
  }
})
  .set(introReveal, { autoAlpha: 1 })
  .from(introPresenting, { opacity: 0, y: 20 })
  .from(introTitle, { opacity: 0, y: 20 }, '<.3')
  .from(introSubtitle, { opacity: 0, y: 20 }, '<.2')
  .from(introReveal, { xPercent: -100, ease: 'power2.out', duration: 3 }, '<')
  .from(introImg, { xPercent: 100, scale: 1.5, duration: 3, ease: 'power2.out' }, '<')



// ****************
// COUNTER
// ****************
// #region about
const statCount = document.querySelector('.intro--feature__number')
gsap.to(statCount, {
  innerText: '100%',
  duration: 2,
  snap: 'innerText',
  scrollTrigger: {
    trigger: '.intro--grid__photo',
    start: 'top 70%',
    end: 'top 30%',
    markers: false,
  }
})
// #endregion


// ****************
// MOVE Animations
// ****************
const move = document.querySelector('.move')
const moveReveal = document.querySelector('.move--reveal')
const moveImage = moveReveal.querySelector('img')
const movePar1 = document.querySelector('.move--grid__text1 p')
const movePar2 = document.getElementById('movePar2')
const applicSquare = document.querySelector('.move--application')
const applic1 = document.getElementById('applic1')
const applic2 = document.getElementById('en')
const applic3 = document.getElementById('applic3')

const moveAnim = gsap.timeline({
  scrollTrigger: {
    trigger: move,
    toggleActions: 'restart none none reset',
    start: 'top 70%',
  }
})
  .set(moveReveal, { autoAlpha: 1 })
  .from(movePar1, { opacity: 0, y: 15 })
  .from(moveReveal, { xPercent: 100, ease: 'power2.out', duration: 3 }, '<')
  .from(moveImage, { xPercent: -100, scale: 1.5, duration: 3, ease: 'power2.out' }, '<')
  .from(movePar2, { opacity: 0, y: 15 }, '-=2.5')
  .from(applicSquare, { xPercent: 100, opacity: 0 }, '<')
  .from(applic1, { xPercent: 100, opacity: 0, ease: 'back' }, '<')
  .from(applic2, { xPercent: -100, opacity: 0, ease: 'back' }, '<+=.2')
  .from(applic3, { xPercent: 100, opacity: 0, ease: 'back' }, '<+=.2')



// ****************
// MF2 Animations
// ****************
const mf2 = document.querySelector('.mf2')
const mf2Title = document.querySelector('.mf2--title')
const mf2Img = document.querySelector('.mf2--photo')
const mf2Texts = document.querySelectorAll('.mf2--content__body')
const mf2Feats = document.querySelectorAll('.mf2--item')

const mf2FeatsAnim = gsap.timeline({
  scrollTrigger: {
    trigger: mf2,
    start: 'top 70%',
  }
})
  .from(mf2Title, { opacity: 0, y: 20 })
  .from(mf2Img, { opacity: 0, y: 20 }, '<+.3')
  .from([mf2Texts], { opacity: 0, y: 20, stagger: 0.3 })
  .from([mf2Feats], { opacity: 0, xPercent: 40, stagger: 0.2 })



// ****************
// MICRO Animations
// ****************
const micro = document.querySelector('.cartridges--section_micro')
const macro = document.querySelector('.cartridges--section_macro')
const microTitle = micro.querySelector('.title')
const microText = micro.querySelector('.cartridges--body')
const macroTitle = macro.querySelector('.title')
const macroText = macro.querySelector('.cartridges--body')
const microCarts = micro.querySelectorAll('.cartridges--item')
const macroCarts = macro.querySelectorAll('.cartridges--item')

const microAnim = gsap.timeline({
  scrollTrigger: {
    trigger: micro,
    start: 'top 70%',
    toggleActions: 'restart none none reset',
  }
})
  .from(microTitle, { opacity: 0, y: 20, duration: 1 })
  .from(microText, { opacity: 0, y: 20 }, '<0.3')
  .from([microCarts], { opacity: 0, y: 20, stagger: 0.25 })


const macroAnim = gsap.timeline({
  scrollTrigger: {
    trigger: macro,
    start: 'top 70%',
    toggleActions: 'restart none none reset',
  }
})
  .from(macroTitle, { opacity: 0, y: 20, duration: 1 })
  .from(macroText, { opacity: 0, y: 20 }, '<0.3')
  .from([macroCarts], { opacity: 0, y: 20, stagger: 0.25 })



// ****************
// EFFECTS Animations
// ****************
const effects = document.querySelector('.effects')
const effectsTitle = effects.querySelector('.title')
const effectsItems = effects.querySelectorAll('.effects--item')

const effectsAnim = gsap.timeline({
  scrollTrigger: {
    trigger: effects,
    start: 'top 70%',
    // toggleActions: 'restart none none reset',
  }
})
  .from(effectsTitle, { opacity: 0, y: 20 })

effectsItems.forEach((item) => {
  const title = item.querySelector('.effects--item__name')
  const text = item.querySelector('.effects--item__body')
  const strong = item.querySelector('.effects--item__strong')

  const effectsItemAnim = gsap.timeline({
    scrollTrigger: {
      trigger: item,
      start: 'top 70%',
      // toggleActions: 'restart none none reset',
    }
  })
    .from(item, { xPercent: 100 })
    .from(title, { opacity: 0, y: 20 })
    .from(text, { opacity: 0, y: 20 })
    .from(strong, { opacity: 0, y: 20 }, '-=.3')
})


// ****************
// TRATAMIENTOS Animations
// ****************
const tratamientos = document.querySelector('.tratamientos')
const trataTitle = tratamientos.querySelector('.title')
const trataText = tratamientos.querySelector('.tratamientos--text')
const trataFeatures = tratamientos.querySelectorAll('.tratamientos--item')

const trataAnim = gsap.timeline({
  scrollTrigger: {
    trigger: tratamientos,
    start: 'top 70%',
  }
})
  .from(trataTitle, { opacity: 0, y: 20 })
  .from(trataText, { opacity: 0, y: 20 }, '<.3')
  .from([trataFeatures], { opacity: 0, xPercent: 40, stagger: 0.2 })


const trataPhotos = document.querySelectorAll('.tratamientos--photos')

trataPhotos.forEach(set => {
  const trataPhotosItems = set.querySelectorAll('.tratamientos--photos__item')

  trataPhotosItems.forEach(item => {
    const trataReveal = item.querySelectorAll('.tratamientos--reveal')
    const trataImage = item.querySelectorAll('img')
    const trataPhotoAnim = gsap.timeline({
      scrollTrigger: {
        trigger: item,
        start: 'top 70%',
      }
    })
    if (item.classList.contains('reverse')) {
      trataPhotoAnim.set(trataReveal, { autoAlpha: 1 })
      trataPhotoAnim.from(trataReveal, { xPercent: -100, ease: 'power2.out', duration: 3 }, '<')
      trataPhotoAnim.from(trataImage, { xPercent: 100, scale: 1.5, duration: 3, ease: 'power2.out' }, '<')
    } else {
      trataPhotoAnim.set(trataReveal, { autoAlpha: 1 })
      trataPhotoAnim.from(trataReveal, { xPercent: 100, ease: 'power2.out', duration: 3 }, '<')
      trataPhotoAnim.from(trataImage, { xPercent: -100, scale: 1.5, duration: 3, ease: 'power2.out' }, '<')

    }
  })
})



// ****************
// VENTAJAS Animations
// ****************
const ventItems = document.querySelectorAll('.ventajas--item')
ventItems.forEach(item => {
  const cross = item.querySelector('.ventajas--cross')
  const line1 = cross.querySelector('.ventajas--cross__line1')
  const line2 = cross.querySelector('.ventajas--cross__line2')
  const detail = item.querySelector('.ventajas--item__detail')
  const target = cross.querySelector('.ventajas--cross__target')

  item.addEventListener('click', () => {
    item.classList.toggle('open')

    if (item.classList.contains('open')) {
      const closeAnim = gsap.timeline()
        .to(line1, { rotate: '0deg' })
        .to(detail, { height: 'auto', marginTop: '1em' }, '<')
    } else {
      const openAnim = gsap.timeline()
        .to(line1, { rotate: '90deg' })
        .to(detail, { height: 0, marginTop: 0 }, '<')
    }
  })
})


// ****************
// RESULTADOS Animations
// ****************
const resultados = document.querySelector('.resultados')
const resTitle = resultados.querySelector('.title')

const resAnim = gsap.timeline({
  scrollTrigger: {
    trigger: resultados,
    start: 'top 70%',
  }
})
  .from(resTitle, { opacity: 0, y: 20 })